<template>
  <div class="ec-platform-welcome">
    {{ $t("home.welcome.hello") }}{{ $t("comma") }}{{ info.name
    }}{{ $t("period") }}{{ $t("home.welcome.login") }}{{ $t("app.name")
    }}{{ $t("period") }}
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  computed: {
    info() {
      return this.$store.state.user.info;
    }
  },
  methods: {},
  mounted() {}
};
</script>

<style lang="less" scoped></style>
